<template>
  <div id="ModalPayment">
    <b-button variant="primary" @click="add()" class="border-light"
      >Registro de pago de pensión</b-button
    >
    <div>
      <b-modal scrollable centered busy v-model="show" title="Cronograma">
        <b-container fluid>
          <!--Contenido modal-->
          <b-row>
          <!--
            <b-alert show variant="danger">
              <b>No están disponible los pagos.</b>
            </b-alert>-->
            
            <b-col lg="12" class="mb-3"
              ><b-button
                :to="
                  '/requisition/' +
                  dataProgram.requisitionPensionPaymentId +
                  '/' +
                  dataProgram.requisitionDetailId
                "
                style="background-color: #5e151d; border: none; color: white"
              >
                Pagar pensión
              </b-button></b-col
            ><!--
            <b-col lg="4"
              ><b-button
                class="w-100"
                style="background-color: #1d425e; border: none; color: white"
                target="_blank"
                :href="$urlFirebase + 'FECHAS_PAGOS' + '.jpg?alt=media'"
              >
                Ver fechas de pagos
              </b-button>
            </b-col>
            -->
          </b-row>
          <b-table striped responsive :items="desserts" :fields="fields">
            <template #cell(month)="row">
              <b>Mes {{ row.item.month }}</b>
            </template>
            <template #cell(state)="row">
              <b-badge pill :variant="returnColor(row.item.color)">{{
                row.item.name
              }}</b-badge>
            </template>
          </b-table>
          <!--fin-->
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="exit()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control"
            >
              Aceptar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPayment",
  props: {
    dataProgram: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      desserts: [],
      fields: [
        { key: "month", label: "Mes", class: "text-center" },
        { key: "state", label: "Estado", class: "text-center" },
      ],
    };
  },
  methods: {
    returnColor(data) {
      if (data == "green") return "success";
      else return "";
    },
    toCourseDetail(data) {
      this.$router.push("/course/" + data);
    },
    exit() {
      this.show = false;
    },
    add() {
      this.initialize();
      this.show = true;
    },
    async initialize() {
      await this.$axios
        .get(
          "/dashboard/enrollment/" + this.dataProgram.enrollId + "/schedule",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.display-3 {
  font-weight: 600;
  -webkit-text-stroke: 1px rgb(61, 61, 61);
}
.lead {
  font-weight: 900;
  -webkit-text-stroke: 0.8px rgb(61, 61, 61);
}
</style>